import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import "./about/teachers/teachers.scss"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuAbout from "../components/sideBarMenu/SidebarMenuAbout"
import Title from "../components/Title"
import TeachersComp from "../components/TeachersComp"
import HelpChoosing from "../components/offers/HelpChoosing"
import FormPopup from "../components/popups/FormPopup"
import {SEO} from "../components/seo";

const Teachers = ({ data, location }) => {
  const [formPopup, setFormPopup] = useState(false)

  const { allStrapiTeacher, strapiTeachersPage } = data

  const mapTeacherData = data => {
    return data
      .map(item => {
        return {
          slug: item.slug,
          imgUrl: item.teacherCard.cover?.url,
          djName: item.djName,
          name: item.name,
          subtitle: item.teacherCard.subtitle,
          teacherNumber: item?.teacher_number ? item.teacher_number : null,
        }
      })
      .sort((a, b) => {
        if (a.teacherNumber === null || b.teacherNumber === 0) return 1
        return a.teacherNumber - b.teacherNumber
      })
  }

  return (
    <div>
      <SEO title={strapiTeachersPage?.titleDesc?.pageTitle} description={strapiTeachersPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="teachersPage" location={location}>
        <DoubleBlock menu={<SidebarMenuAbout setFormPopup={setFormPopup} />}>
          <Title
            classAdd=""
            value={[
              ["dark", "Наши"],
              ["red", " преподаватели"],
            ]}
          />

          {
            // TEACHER CARDS
            allStrapiTeacher?.nodes && (
              <TeachersComp
                data={mapTeacherData(allStrapiTeacher.nodes)}
                classAdd="m60_40"
              />
            )
          }

          {
            // OFFER
            strapiTeachersPage?.offer && (
              <HelpChoosing classAdd="m140" data={strapiTeachersPage.offer} />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    allStrapiTeacher {
      nodes {
        slug
        strapi_id
        djName
        name
        teacher_number
        teacherCard {
          cover {
            url
          }
          subtitle
        }
      }
    }
    strapiTeachersPage {
      offer {
        title
        subtitle
        button
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`

export default Teachers
