import * as React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import Img from "react-cool-img"
import { STRAPI_API } from "../consts/api"
import { MENU_LINKS } from "../consts/MenuLinks"

export default function TeachersComp(props) {
  const { data, classAdd } = props

  return (
    <div className={classAdd ? `${classAdd} teachers` : "teachers"}>
      <div className="teachers__cont">
        {data.map((item, i) => (
          <div className="teachers__item" key={i}>
            <Link to={MENU_LINKS.teachers + `${item.slug}`}>
              <motion.div
                className="teachers__box"
                whileHover={{ scale: 1.05 }}
              >
                {item?.imgUrl && (
                  <div className="teachers-img">
                    <Img
                      placeholder={
                        STRAPI_API +
                        item.imgUrl +
                        "?format=webp&width=40&grayscale=true"
                      }
                      src={STRAPI_API + item.imgUrl}
                      draggable={false}
                      lazy={true}
                      alt="img"
                    />
                  </div>
                )}

                <div className="teachers-cont">
                  {item?.djName && <h2>{item.djName}</h2>}
                  {item?.name && <h3>{item.name}</h3>}
                  {item?.subtitle && <p>{item.subtitle}</p>}
                  {item?.id && (
                    <Link
                      className="teachers-more"
                      to={MENU_LINKS.teachers + `/${item.id}`}
                    >
                      Подробнее
                    </Link>
                  )}
                </div>
              </motion.div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}
